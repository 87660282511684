<template>
  <v-container
    fluid
    id="regular-table"
  >
    <v-form
      ref="form"
      v-model="is_valid"
      lazy-validation
    >
      <div
        class="d-flex flex-column flex-md-row"
        style="gap: 20px"
      >
        <div class="me-6">
          <h3 class="text-h3 ma-4">Enable sub category</h3>

          <v-flex>
            <div
              class="text-h4 d-flex justify-space-between"
              style="max-width: 285px"
            >
              <span class="text-h4 ma-4">Special categories</span>
              <v-switch
                class="text-h4"
                color="success"
                v-model="special_category_settings"
                :label="`${special_category_settings ? 'Active' : 'Inactive'}`"
              >
              </v-switch>
            </div>
          </v-flex>

          <v-flex>
            <div
              class="text-h4 d-flex justify-space-between"
              style="max-width: 285px"
            >
              <span class="text-h4 ma-4">Categories</span>
              <v-switch
                class="text-h4"
                color="success"
                v-model="category_settings"
                :label="`${category_settings ? 'Active' : 'Inactive'}`"
              >
              </v-switch>
            </div>
          </v-flex>
        </div>
      </div>
    </v-form>
    <div class="ma-4">
      <v-btn
        class="primary"
        @click="save()"
        v-if="$admin.can('setting-specialCategory')"
        :loading="isLoading"
        >save changes</v-btn
      >
      <v-btn
        color="indigo"
        text
        @click="cancel()"
        >cancel</v-btn
      >
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      is_valid: false,
      special_category_settings: false,
      category_settings: false,
      isLoading: false,
    };
  },
  methods: {
    async save() {
      this.validate();
      if (this.is_valid) {
        try {
          this.isLoading = true;
          await this.$store.dispatch(
            "categories/updateSpecialCategorySettings",
            {
              automatic_enable_sub_category: this.special_category_settings,
            }
          );
          await this.$store.dispatch("categories/updateCategorySettings", {
            automatic_enable_sub_category: this.category_settings,
          });
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.isLoading = false;
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    cancel() {
      this.$router.push("/dashboard");
    },
    async load() {
      await this.$store
        .dispatch("categories/SpecialCategorySetting")
        .then(async (data) => {
          this.special_category_settings =
            data.special_category_settings.automatic_enable_sub_category;
        });
      await this.$store
        .dispatch("categories/CategorySetting")
        .then(async (data) => {
          this.category_settings =
            data.category_settings.automatic_enable_sub_category;
        });
    },

    init() {
      this.load();
    },
  },
  created() {
    this.init();
  },
};
</script>
